import { createContext, useContext, useState } from 'react';

import localStorageManger from '../local-storage-manager.service';
import networker from '../networking';

import { User } from '../../types/models/user';

type AuthType = {
  user: User;
  login: (user: User, token: string) => void;
  logout: () => void;
  refetchCurrentUser: () => void;
}

const defaultAuthContextData: AuthType = {
  user: null,
  login: (user: User, token: string) => {},
  logout: () => {},
  refetchCurrentUser: () => {},
}

export const authContext = createContext(defaultAuthContextData);

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth(): AuthType {
  const [user, setUser]: [User, any] = useState(null);

  const login = (user: User, token: string) => { 
    localStorageManger.setToken(token);
    setUser(user);
  };
  
  const logout = () => {
    localStorageManger.clearToken();
    setUser(null);
  };

  const refetchCurrentUser = () => {
    networker.getCurrentUser()
      .then((user: User ) => {
        setUser(user);
      })
      .catch(err => console.log('Networking error: ', err));
  };

  return {
    user,
    login,
    logout,
    refetchCurrentUser
  };
}
