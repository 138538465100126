import React from 'react';

import './NavigationBar.scss';
import { Location } from '../../router/navigation-bar-locations';

import { NavigationLinksLine } from './NavigationLinksLine/NavigationLinksLine';


interface INavigationBarProps {
  locations: Location[];
  hint?: string;
};

export function NavigationBar({ locations, hint }: INavigationBarProps) {
  return (
    <nav className='NavigationBar'>
      <NavigationLinksLine locations={ locations }/>
      <h3 className='NavigationBar__hint'>
        { hint ? hint : 'Navigation' }
      </h3>
    </nav>
  );
}
