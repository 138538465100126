import ReactGA, { EventArgs } from 'react-ga';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

function init() {
  // Enable debug mode on the local development environment
  if (!GA_TRACKING_ID) {
    return console.log('GoogleAnalytics service error.');
  }
  // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  // ReactGA.initialize(GA_TRACKING_ID, { debug: isDev });
  ReactGA.initialize(GA_TRACKING_ID, { debug: false, gaOptions: { cookieFlags: 'SameSite=None; Secure' }});
}

function sendEvent(payload: EventArgs) {
  ReactGA.event(payload);
}

function sendPageview(path: string) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview,
};
