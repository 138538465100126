// TODO: translate the code to TypeScript.

export function currentYPosition() {
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

export function elmYPosition(eID: string) {
  var elm: any = document.getElementById(eID);
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  } return y;
}

export function smoothScrollToElement(eID: string) {
  var startY = currentYPosition();
  var stopY = elmYPosition(eID);
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    window.scrollTo(0, stopY); return;
  }
  var speed = Math.round(distance / 100);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for ( var i=startY; i<stopY; i+=step ) {
      setTimeout('window.scrollTo(0, '+leapY+')', timer * speed);
      leapY += step; if (leapY > stopY) leapY = stopY; timer++;
    } return;
  }
  for (var j=startY; j>stopY; j-=step) {
    setTimeout('window.scrollTo(0, '+leapY+')', timer * speed);
    leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
  }
}

export function smoothScrollTop() {
  var startY = currentYPosition();
  var stopY = 0;
  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    window.scrollTo(0, stopY); return;
  }
  var speed = Math.round(distance / 100);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i=startY; i<stopY; i+=step) {
      setTimeout('window.scrollTo(0, '+leapY+')', timer * speed);
      leapY += step; if (leapY > stopY) leapY = stopY; timer++;
    } return;
  }
  for (var j=startY; j>stopY; j-=step) {
    setTimeout('window.scrollTo(0, '+leapY+')', timer * speed);
    leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
  }
}
