export class LocalStorageManger {
  private LOCAL_STORAGE_PROP_NAME = {
    TOKEN: 'token',
    MIGRATION: 'migrationUserToken'
  };

  getToken(): string | null {
    return localStorage.getItem(this.LOCAL_STORAGE_PROP_NAME.TOKEN);
  }

  setToken(token: string): void {
    localStorage.setItem(this.LOCAL_STORAGE_PROP_NAME.TOKEN, token);
    localStorage.setItem(this.LOCAL_STORAGE_PROP_NAME.MIGRATION, '0');
  }

  clearToken() {
    localStorage.removeItem(this.LOCAL_STORAGE_PROP_NAME.TOKEN);
    localStorage.removeItem(this.LOCAL_STORAGE_PROP_NAME.MIGRATION);
  }
}

export default new LocalStorageManger();
