import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  useHistory
} from 'react-router-dom';

import { LOCATIONS } from './navigation-bar-locations';
import localStorageManager from '../services/local-storage-manager.service';
import networker from '../services/networking';
import { useAuth } from '../services/auth/auth-context';
import { ROUTER_ENDPOINTS } from './router-endpoints';

import { NavigationBar } from '../components/NavigationBar/NavigationBar';
import { RoutesSwitch } from './RoutesSwitch';
import { Loader } from '../components/Loader/Loader';
import { Footer } from '../components/Footer/Footer';

export function AppRouter() {
  const [isUserRequested, setIsUserRequested] = useState(false);
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    const token = localStorageManager.getToken();
    if (!token) return setIsUserRequested(true);
    networker.getCurrentUser()
      .then(resUser => {
        if (resUser) {
          auth.login(resUser, token);
        } else if (auth.user) {
          auth.logout();
          history.push(ROUTER_ENDPOINTS.AUTH)
        }
        setIsUserRequested(true);
      })
      .catch(console.log);
  }, []);  

  if (!isUserRequested) {
    return <Loader />
  }
  return (
    <Router>
      <div className='container'>
        <header>
          <NavigationBar locations={ LOCATIONS } />
        </header>
        <RoutesSwitch />
        <Footer />
      </div>
    </Router>
  );
}
