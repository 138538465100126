import React, { MouseEvent } from 'react';
import { Block3dType } from '../../../types/block-3d-type';
import './Button.scss';

export type HandleButtonClick = (e: MouseEvent<HTMLButtonElement>) => void;

interface IButtonProps {
  text?: string;
  buttonType?: Block3dType | undefined;
  handleClick?: HandleButtonClick;
  icon?: any;
  doNotCompensateBorder?: boolean;
  disablePointerEvents?: boolean
  isDisabled?: boolean;
  isPressed?: boolean;
  isHidden?: boolean;
}

export function Button({ buttonType, text, icon, handleClick, doNotCompensateBorder, disablePointerEvents, isDisabled, isPressed, isHidden }: IButtonProps) {
  const CSS_BUTTON = 'Button';
  const CSS_TYPE = buttonType ? buttonType : '';
  const CSS_HAS_ICON = icon ? `${CSS_BUTTON}__has-icon` : '';
  const CSS_POINTER_EVENTS = disablePointerEvents ? `${CSS_BUTTON}__no-pointer-events` : '';
  const CSS_IS_PRESSED = isPressed ? `${CSS_BUTTON}__pressed` : '';
  const CSS_IS_HIDDEN = isHidden ? `${CSS_BUTTON}__hidden` : '';
  const cssClass = `${CSS_BUTTON} ${CSS_TYPE} ${CSS_HAS_ICON} ${CSS_POINTER_EVENTS} ${CSS_IS_PRESSED} ${CSS_IS_HIDDEN}`;

  const buttonText = text || '';
  const renderText = () => text
    ? <div className={ `${ CSS_BUTTON }__text` }>{buttonText}</div>
    : null;

  const renderIcon = () => icon
    ? <img src={icon} className={ `${ CSS_BUTTON }__icon` } alt='button-icon'/>
    : null;

  return (
    <div className={ doNotCompensateBorder ? '' : 'Button__compensate-border' }>
      <button type='button' className={cssClass} onMouseUp={handleClick} disabled={isDisabled}>
        { renderIcon() }
        { renderText() }
      </button>
    </div>
  );
}
