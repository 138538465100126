import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import './NavigationLink.scss';

import { Location } from '../../../../router/navigation-bar-locations';

import { Button } from '../../../basic/Button/Button';
import { ROUTER_ENDPOINTS } from '../../../../router/router-endpoints';
import { useAuth } from '../../../../services/auth/auth-context';

interface INavigationLinkProps {
  location: Location;
};

export function NavigationLink({ location }: INavigationLinkProps) {
  const auth = useAuth();
  const uid = auth?.user?.uid;
  const [locationPath, setLocationPath] = useState(location.path);

  useEffect(() => {
    if (location.path === ROUTER_ENDPOINTS.PROFILE) {
      setLocationPath(`${location.path}/${auth.user?.uid || ''}`);
    }
  }, [uid]);
  
  return (
    <NavLink to={ locationPath } activeClassName='NavigationLink__is-active' exact={location.isExact}>
      <Button
        text={ location.name }
        buttonType='neutral'
        doNotCompensateBorder={true}
        disablePointerEvents={true}
      />
    </NavLink>
  )
}
