import React, { Suspense, lazy } from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';

import { ROUTER_ENDPOINTS } from './router-endpoints';
import { useGoogleAnalytics } from '../services/google-analytics/useGoogleAnalyticsHook';
import { RoutePrivate } from '../services/auth/provider/RoutePrivate';
import { ScrollToTopOnMount } from './ScrollToTopOnMount';
import { Loader } from '../components/Loader/Loader';
import { ErrorBoundary } from './ErrorBoundary';

const PostDetails = lazy(() => import('../pages/PostDetails/PostDetails').then(module => ({ default: module.PostDetails })));
const Profile = lazy(() => import('../pages/Profile/Profile').then(module => ({ default: module.Profile })));
const Landing = lazy(() => import('../pages/Landing/Landing').then(module => ({ default: module.Landing })));
const Explore = lazy(() => import('../pages/Explore/Explore').then((module) => ({ default: module.Explore })));  
const ContactUs = lazy(() => import('../pages/ContactUs/ContactUs').then(module => ({ default: module.ContactUs })));
const Auth = lazy(() => import('../pages/Auth/Auth').then(module => ({ default: module.Auth })));
const Page404 = lazy(() => import('../pages/Page404/Page404').then(module => ({ default: module.Page404 })));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions/TermsAndConditions').then(module => ({ default: module.TermsAndConditions })));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy').then(module => ({ default: module.PrivacyPolicy })));
const CookiesPolicy = lazy(() => import('../pages/CookiesPolicy/CookiesPolicy').then(module => ({ default: module.CookiesPolicy })));

export function RoutesSwitch() {
  useGoogleAnalytics();
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader/>}>
        <Switch>
          <Route path={ ROUTER_ENDPOINTS.CONTACT_US }>
            <ScrollToTopOnMount />
            <ContactUs />
          </Route>

          <Route path={ ROUTER_ENDPOINTS.TERMS_AND_CONDITIONS }>
            <ScrollToTopOnMount />
            <TermsAndConditions />
          </Route>

          <Route path={ ROUTER_ENDPOINTS.PRIVACY_POLICY }>
            <ScrollToTopOnMount />
            <PrivacyPolicy />
          </Route>

          <Route path={ ROUTER_ENDPOINTS.COOKIES_POLICY }>
            <ScrollToTopOnMount />
            <CookiesPolicy />
          </Route>
          
          <Route path={ `${ROUTER_ENDPOINTS.POST_DETAILS}/:postUid` }>
            <ScrollToTopOnMount />
            <PostDetails />
          </Route>

          <Route path={ ROUTER_ENDPOINTS.DETAILS }>
            <ScrollToTopOnMount />
            <PostDetails />
          </Route>
          
          <RoutePrivate path={ `${ROUTER_ENDPOINTS.PROFILE}/:userUid` }>
            <ScrollToTopOnMount />
            <Profile />
          </RoutePrivate>
          
          <RoutePrivate path={ ROUTER_ENDPOINTS.EXPLORE }>
            <ScrollToTopOnMount />
            <Explore />
          </RoutePrivate>

          <Route path={ ROUTER_ENDPOINTS.AUTH }>
            <ScrollToTopOnMount />
            <Auth />
          </Route>

          <Route path={ ROUTER_ENDPOINTS.LANDING } exact={true}>
            <ScrollToTopOnMount />
            <Landing />
          </Route>

          <Route path='/test'>
            <ScrollToTopOnMount />
            <Auth />
          </Route>

          <Route path="*">
            <ScrollToTopOnMount />
            <Page404 />
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}
