import axios from 'axios';

import { Post, PostResponse } from '../../types/models/posts';
import { IPostReactionCreateData, IUserCommentReactionCreateData, PostReactionResponse, UserCommentReactionResponse } from '../../types/models/reaction';
import { UserComment, UserCommentResponse } from '../../types/models/user-comment';
import { API_ENDPOINTS } from './api-endponts';
import { User } from '../../types/models/user';
import localStorageManager from '../local-storage-manager.service';

type GoogleUserData = {
  profile: object;
  token: object;
};

// export type FacebookUserData = {
//   accessToken: string;
//   data_access_expiration_time: number;
//   expiresIn: number;
//   graphDomain: "facebook"
//   id: string;
//   name: string;
//   signedRequest: string;
//   userID: string;
// };

type QueryParams = { [param: string]: number | string };

class Networker {
  // Utils:
  private request(method: 'GET' | 'POST', url: string, data: any = null, params?: QueryParams): Promise<any> {
    const token = localStorageManager.getToken();
    return axios({
      method,
      url,
      data,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      params,
    });
  }
  private get(url: string, params?: QueryParams) {
    return this.request('GET', url, null, params);
  }
  private post(url: string, data: any = null) {
    return this.request('POST', url, data);
  }

  private onError(err: any) {
    if (err.response.status === 404) {
      // TODO: make redirect with react. Not with window.
      window.location.pathname = 'not-found';
    }
    console.log('Networking error:', err);
  }

  // User:
  async getCurrentUser() {
    const url = API_ENDPOINTS.USER.CURRENT;
    try {
      const res = await this.get(url);
      // console.log('getCurrentUser', res.data)
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }
      this.onError(err);
    }
  }
  
  async loginOrCreateUserGoogle(userData: GoogleUserData): Promise<{ user: User, token: string } | undefined> {
    const url = API_ENDPOINTS.LOGIN.GOOGLE;
    try {
      const res = await this.post(url, userData);
      // console.log('loginOrCreateUserGoogle', res.data)
      return res.data;
    } catch (err) {
      this.onError(err)
    }
  }

  async loginOrCreateUserFacebook(userData: { accessToken: string; userID: string; }): Promise<{ user: User, token: string } | undefined> {
    const url = API_ENDPOINTS.LOGIN.FACEBOOK;
    try {
      const res = await this.post(url, userData);
      // console.log('loginOrCreateUserFacebook', res.data)
      return res.data;
    } catch (err) {
      this.onError(err)
    }
  }

  async getUserByUid(userUid: string): Promise<User | undefined> {
    const url = `${API_ENDPOINTS.USER.BY_ID}/${userUid}`;
    try {
      const res = await this.get(url);
      // console.log('getUserByUid', res.data)
      return res.data;
    } catch (err) {
      this.onError(err)
    }
  }
  
  // Posts:
  async createPost(data: Post): Promise<PostResponse | undefined> {
    const url = API_ENDPOINTS.POSTS;
    try {
      const res = await this.post(url, data);
      // console.log('createPost', res.data)
      return res.data;
    } catch (err) {
      this.onError(err)
    }
  }

  async getLastThreePosts(): Promise<PostResponse[] | undefined> {
    const url = API_ENDPOINTS.LAST_THREE_POSTS;
    try {
      const res = await this.get(url);
      // console.log('getLastThreePosts', res.data)
      return res.data;
    } catch (err) {
      this.onError(err)
    }
  }

  async getPosts({ limit = 16, page, userUid }: { limit?: number, page?: number, userUid?: string } = {}): Promise<{ posts: PostResponse[], count: number } | undefined> {
    const url = API_ENDPOINTS.POSTS;
    const params: QueryParams = {};
    if (limit) params.limit = limit;
    if (page) params.page = page;
    if (userUid) params.userUid = userUid;
    try {
      const res = await this.get(url, params);
      // console.log('getPosts', res.data)
      return res.data;
    } catch (err) {
      this.onError(err)
    }
  }

  async getPostByUid({ postUid }: { postUid?: string }): Promise<PostResponse | undefined> {
    const url = `${API_ENDPOINTS.POST}/${postUid}`;
    try {
      const res = await this.get(url);
      // console.log('getPostByUid', res.data)
      return res.data;
    } catch (err) {
      this.onError(err)
    }
  }

  // Comments:
  async getCommentsByPostUid({ postUid, limit = 16, page }: { postUid: string, limit?: number, page?: number }): Promise<{ comments: UserCommentResponse[], count: number } | undefined> {
    const url = `${API_ENDPOINTS.COMMENTS}/${postUid}`;
    const params: QueryParams = {};
    if (limit) params.limit = limit;
    if (page) params.page = page;
    try {
      const res = await this.get(url, params);
      // console.log('getCommentsByPostUid', res.data)
      return res.data;
    } catch (err) {
      this.onError(err);
    }
  }

  async createComment({ postUid, commentData }: { postUid: string, commentData: UserComment }): Promise<UserCommentResponse | undefined> {
    const url = `${API_ENDPOINTS.COMMENTS}/${postUid}`;
    try {
      const res = await this.post(url, commentData);
      // console.log('createComment', res.data)
      return res.data;
    } catch (err) {
      this.onError(err)
    }
  }

  // Reactions:
  async createReactionPost({ type, authorUid, postUid }: IPostReactionCreateData): Promise<PostReactionResponse | undefined> {
    const url = `${API_ENDPOINTS.REACTIONS.POST}/${postUid}`;
    try {
      const res = await this.post(url, { type, authorUid });
      // console.log('createReactionPost', res.data)
      return res.data;
    } catch (err) {
      // TODO: What should happen on error?
      // this.onError(err)
    }
  }

  async createReactionUserComment({ type, authorUid, userCommentUid }: IUserCommentReactionCreateData): Promise<UserCommentReactionResponse | undefined> {
    const url = `${API_ENDPOINTS.REACTIONS.USER_COMMENT}/${userCommentUid}`;
    try {
      const res = await this.post(url, { type, authorUid });
      // console.log('createReactionUserComment', res.data)
      return res.data;
    } catch (err) {
      // TODO: What should happen on error?
      // this.onError(err)
    }
  }

}

export default new Networker();
