import { useEffect } from 'react';
import { smoothScrollTop } from '../utils/scroll';

export function ScrollToTopOnMount() {
  useEffect(() => {
    smoothScrollTop();
  }, []);

  return null;
}
