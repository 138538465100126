const BASE = '/api/v1';

export const API_ENDPOINTS = {
  LAST_THREE_POSTS: `${BASE}/last-three-posts`,
  POSTS: `${BASE}/posts`,
  POST: `${BASE}/posts`,
  REACTIONS: {
    POST: `${BASE}/reactions/post`,
    USER_COMMENT: `${BASE}/reactions/user-comment`,
  },
  COMMENTS: `${BASE}/comments`,
  USER: {
    CURRENT: `${BASE}/current-user`,
    BY_ID: `${BASE}/user`,
  },
  LOGIN: {
    GOOGLE: `${BASE}/login/google`,
    FACEBOOK: `${BASE}/login/facebook`,
    TWITTER: `${BASE}/login/twitter`,
  },
  LOGOUT: `${BASE}/logout`,
};
