import React from 'react';

import './ImageHeading.scss';

interface IImageHeadingProps {
  image: string;
  title: string;
  subtitle?: string;
  isTitleColorMixed?: boolean;
  isSubtitleColorMixed?: boolean;
};

export function ImageHeading({ image, title, subtitle, isTitleColorMixed, isSubtitleColorMixed }: IImageHeadingProps) {
  return (
    <div className='ImageHeading'>
      <img src={ image } className='ImageHeading__image' alt='my-posts'/>
      <h2 className={ 'ImageHeading__title' + (isTitleColorMixed ? ' ImageHeading__color-mixed' : '') }>{ title }</h2>
      {
        subtitle
          ? <h4 className={ 'ImageHeading__subtitle' + (isSubtitleColorMixed ? ' ImageHeading__color-mixed' : '') }>{ subtitle }</h4>
          : null
      }
    </div>
  );
}
