import React from 'react';
import { useHistory } from 'react-router-dom';

import './Footer.scss';
import { ROUTER_ENDPOINTS } from '../../router/router-endpoints';
import { useAuth } from '../../services/auth/auth-context';

import { SectionTitle } from '../SectionTitle/SectionTitle';

export function Footer() {
  const auth = useAuth();
  const history = useHistory();
  function handleLogout() {
    if (auth.user) {
      auth.logout();
      history.push(ROUTER_ENDPOINTS.AUTH);
    }
  }
  return (
    <footer className='Footer'>
      <SectionTitle title='' />
      <ul className='Footer__links'>
        <li className='Footer__item'>
          <a className='Footer__link' href={ ROUTER_ENDPOINTS.CONTACT_US }>Contact Us</a>
        </li>
        <li className='Footer__item'>
          <a className='Footer__link' href={ ROUTER_ENDPOINTS.PRIVACY_POLICY }>Privacy Policy</a>
        </li>
        <li className='Footer__item'>
          <a className='Footer__link' href={ ROUTER_ENDPOINTS.TERMS_AND_CONDITIONS }>Terms and Conditions</a>
        </li>
        <li className='Footer__item'>
          <a className='Footer__link' href={ ROUTER_ENDPOINTS.COOKIES_POLICY }>Cookies Policy</a>
        </li>
        {
          auth.user
            ? (
              <li className='Footer__item Footer__logout-link'>
                <a className='Footer__link Footer__color-blank' onClick={ handleLogout }>Logout</a>
              </li>
            )
            : null
        }
      </ul>
    </footer>
  );
}
