export const ROUTER_ENDPOINTS = {
  LANDING: '/',
  AUTH: '/auth',
  CONTACT_US: '/contact-us',
  PROFILE: '/profile',
  EXPLORE: '/explore',
  POST_DETAILS: '/post',
  DETAILS: '/details',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  COOKIES_POLICY: '/cookies-policy',
};
