import { ROUTER_ENDPOINTS } from './router-endpoints';

export type Location = {
  name: string;
  path: string;
  isExact?: boolean;
  isHiddenForLoggedInUser: boolean;
  isHiddenIfNotLoggedInUser: boolean;
};

export const LOCATIONS: Location[] = [
  {
    name: 'Home',
    path: ROUTER_ENDPOINTS.LANDING,
    isExact: true,
    isHiddenForLoggedInUser: false,
    isHiddenIfNotLoggedInUser: false,
  },
  {
    name: 'Explore',
    path: ROUTER_ENDPOINTS.EXPLORE,
    isExact: false,
    isHiddenForLoggedInUser: false,
    isHiddenIfNotLoggedInUser: true,
  },
  {
    name: 'Profile',
    path: ROUTER_ENDPOINTS.PROFILE,
    isExact: false,
    isHiddenForLoggedInUser: false,
    isHiddenIfNotLoggedInUser: true,
  },
  {
    name: 'Join',
    path: ROUTER_ENDPOINTS.AUTH,
    isExact: false,
    isHiddenForLoggedInUser: true,
    isHiddenIfNotLoggedInUser: false,
  }
];
