import React from 'react';
import './SectionTitle.scss';

interface ISectionTitleProps {
  title: string;
};

export function SectionTitle({ title }: ISectionTitleProps) {
  return (
    <h3 className='SectionTitle'>
      { title }
    </h3>
  );
}
