import React from 'react';
import { useProvideAuth, authContext } from '../auth-context';

// Provide auth context to children
export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}
