import React from 'react';
import './Loader.scss';

import logo from '../../styles/assets/icons/ITUS_black_hand.svg';

interface ILoaderProps {
  isEmbedded?: boolean;
};

export function Loader({ isEmbedded }: ILoaderProps) {
  return (
    <div className={`Loader ${ isEmbedded ? 'Loader--is-embedded' : '' }`}>
      <div className="Loader__content">
        <div className="Loader__image-wrapper">
          <img className='Loader__image' src={ logo } alt='icon' />
        </div>
        <div className="Loader__text">
          Loading...
        </div>
      </div>
    </div>
  );
}
