import React from 'react';

import { ImageHeading } from '../../components/ImageHeading/ImageHeading';

import './PageGenericError.scss';
import image from '../../styles/assets/icons/page-404.svg';

export function PageGenericError() {
  return (
    <div className='PageGenericError'>

      <ImageHeading
        image={ image }
        title='Something went wrong'
      />

      <div className='PageGenericError__message'>
        <p>Sorry for that.</p>
      </div>
    </div>
  );
}
