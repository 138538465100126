import React from 'react';

import { ProvideAuth } from './services/auth/provider/ProvideAuth';
import { AppRouter } from './router/AppRouter';

function App() {
  return (
    <ProvideAuth>
      <AppRouter />
    </ProvideAuth>
  );
}

export default App;
