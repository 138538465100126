import React from 'react';
import './NavigationLinksLine.scss';

import { Location } from '../../../router/navigation-bar-locations';
import { NavigationLink } from './NavigationLink/NavigationLink';
import { useAuth } from '../../../services/auth/auth-context';

interface INavigationLinksLineProps {
  locations: Location[];
};

export function NavigationLinksLine({ locations }: INavigationLinksLineProps) {
  const locationsQuantity = locations.length;
  const auth = useAuth();

  const renderedLocations = locations.map((location: Location, index: number) => 
    (location.isHiddenForLoggedInUser && auth.user) || (location.isHiddenIfNotLoggedInUser && !auth.user)
      ? null
      : (
        <li
          className='NavigationLinksLine__item'
          key={index}
          style={{ zIndex: locationsQuantity - index }}
        >
          <NavigationLink
            location={location}
          />
        </li>
      )
  );

  return (
    <ul className='NavigationLinksLine'>
      { renderedLocations }
    </ul>
  );
}
